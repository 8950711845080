.form-textarea-autosize {
  display: flex;
  flex-direction: column;
  height: 100%;
  > div {
    height: 100%;
    > div {
      height: 100%;
      // textarea {
      //   height: 100% !important;
      // }
    }
  }
}
