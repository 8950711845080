@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?nttn5t');
  src: url('fonts/icomoon.eot?nttn5t#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?nttn5t') format('truetype'),
    url('fonts/icomoon.woff?nttn5t') format('woff'),
    url('fonts/icomoon.svg?nttn5t#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ic_form_builder:before {
  content: '\e900';
}
.icon-ic_conditions:before {
  content: '\e901';
}
.icon-ic_delay:before {
  content: '\e902';
}
.icon-ic_actions:before {
  content: '\e903';
}
.icon-ic_events:before {
  content: '\e904';
}
.icon-ic_filter_full:before {
  content: '\e905';
}
.icon-ic_filter:before {
  content: '\e906';
}
.icon-ic_column:before {
  content: '\e907';
}
.icon-ic_percentage:before {
  content: '\e908';
}
.icon-ic_workflow:before {
  content: '\e909';
}
.icon-menu-close:before {
  content: '\e94b';
}
.icon-menu-open:before {
  content: '\e94c';
}
.icon-ic_assessment:before {
  content: '\e94d';
}
.icon-ic_dollar:before {
  content: '\e94e';
}
.icon-ic_headphones:before {
  content: '\e94f';
}
.icon-ic_help:before {
  content: '\e950';
}
.icon-ic_home:before {
  content: '\e951';
}
.icon-ic_location:before {
  content: '\e952';
}
.icon-ic_noti:before {
  content: '\e953';
}
.icon-ic_arrow_left:before {
  content: '\e954';
}
.icon-ic_arrow_right:before {
  content: '\e955';
}
.icon-ic_arrow-down:before {
  content: '\e956';
}
.icon-ic_arrow-up:before {
  content: '\e957';
}
.icon-ic_calendar:before {
  content: '\e958';
}
.icon-ic_check:before {
  content: '\e959';
}
.icon-ic_chevron-down:before {
  content: '\e95a';
}
.icon-ic_chevron-left:before {
  content: '\e95b';
}
.icon-ic_chevron-right:before {
  content: '\e95c';
}
.icon-ic_chevrons-left:before {
  content: '\e95d';
}
.icon-ic_chevrons-right:before {
  content: '\e95e';
}
.icon-ic_clock:before {
  content: '\e95f';
}
.icon-ic_close:before {
  content: '\e960';
}
.icon-ic_conversation:before {
  content: '\e961';
}
.icon-ic_download:before {
  content: '\e962';
}
.icon-ic_edit:before {
  content: '\e963';
}
.icon-ic_eye-off:before {
  content: '\e964';
}
.icon-ic_fax:before {
  content: '\e965';
}
.icon-ic_grid:before {
  content: '\e967';
}
.icon-ic_image:before {
  content: '\e968';
}
.icon-ic_info:before {
  content: '\e969';
}
.icon-ic_list:before {
  content: '\e96a';
}
.icon-ic_mail:before {
  content: '\e96b';
}
.icon-ic_map-pin:before {
  content: '\e96c';
}
.icon-ic_menu:before {
  content: '\e96d';
}
.icon-ic_more-vertical:before {
  content: '\e96e';
}
.icon-ic_mouse_pointer:before {
  content: '\e96f';
}
.icon-ic_move:before {
  content: '\e970';
}
.icon-ic_paperclip:before {
  content: '\e971';
}
.icon-ic_password_view:before {
  content: '\e972';
}
.icon-ic_permission:before {
  content: '\e973';
}
.icon-ic_plus:before {
  content: '\e974';
}
.icon-ic_repos:before {
  content: '\e975';
}
.icon-ic_search:before {
  content: '\e976';
}
.icon-ic_shuffle:before {
  content: '\e977';
}
.icon-ic_sort:before {
  content: '\e978';
}
.icon-ic_thumbs-down:before {
  content: '\e979';
}
.icon-ic_thumbs-up:before {
  content: '\e97a';
}
.icon-ic_trash:before {
  content: '\e97b';
}
.icon-ic_user:before {
  content: '\e97c';
}
