@use 'src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.cmp-table-basic {
  position: relative;

  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}