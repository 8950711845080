@use '~src/scss/vars' as *;
@use '~src/scss/mixin' as *;

.cpm-container-text-area-auto-size {
  border: $default-border;
  border-radius: $input-border-radius;
  &:focus {
    // box-shadow: $focus-shadow;
    border: $default-border-primary;
  }

  &--error {
    border-color: $color-danger;

    &:focus {
      box-shadow: $error-shadow;
    }
  }

  &--disabled,
  &:disabled {
    background-color: #f5f5f5 !important;
  }
}

.cpm-container-border-text-area-auto-size {
  display: flex;
  padding-left: 6px;
  padding-right: 4px;
  width: 100%;

  &--disabled,
  &:disabled {
    background-color: #f5f5f5 !important;
  }
}

.cmp-text-area-auto-size {
  display: table;
  overflow-y: auto !important;
  height: 72px;
  padding: 4px 0px;
  background-color: $color-white;
  transition: $default-transition;
  font-size: $body-size;
  min-height: $input-small;
  scrollbar-width: none !important;
  resize: none !important;

  &__icon {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);

    @include hover;
  }

  &__resize--horizontal {
    resize: horizontal;
  }

  &__resize--vertical {
    resize: vertical;
  }

  &__resize--both {
    resize: both;
  }

  &__resize--none {
    resize: none;
  }
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
