.custom_menu_container {
  border: 1px solid #cccccc;
  border-radius: 2px;
  padding: 4px 0px 4px 10px;
  height: 32px;
}

.custom_menu--dropdown {
  width: 380px !important;
}

// .custom_menu--dropdown--item {
//   // padding: 2px 10px;
// }
.custom_menu--dropdown--item--focus {
  background-color: #525252 !important;
  color: white !important;
  p {
    color: white !important;
  }
}

.custom_menu_indicator_separate {
  // border-right: 1px solid #cccccc;
  height: 100%;
  background-color: #91979e;
  width: 1px;
}

.custom_menu_indicator {
  width: 42px;
  height: 20px;
  margin-top: 2px;
  &_svg {
    fill: #999999;
  }
}

.custom_menu_container--focus {
  border-color: #333333;
  .custom_menu_indicator_separate {
    background-color: #333333 !important;
  }
  .custom_menu_indicator {
    &_svg {
      fill: #333333;
    }
  }
}
