.multiselect_checkbox-control_all {
  display: flex;
  justify-content: space-between;
  padding: 12px 12px;
  .btn_control {
    cursor: pointer;
    color: #0088cc;
    font-weight: 700;
    font-size: 14px;
  }
}

.multiselect_checkbox {
  > div {
    > div:nth-child(2) > div {
      padding: 6px !important;
    }
  }
  .css-1n6sfyn-MenuList {
    > div:not(:first-child) {
      cursor: pointer;
      &:hover {
        background-color: #ececec !important;
        cursor: pointer;
      }
    }
  }
  .css-1hb7zxy-IndicatorsContainer {
    height: 32px;
  }
  .css-t3ipsp-control {
    border-width: 1px;
    border-radius: 2px;
    box-shadow: none;
    border-color: #b9b9b9;
    &:hover {
      border-color: #333333;
    }
  }
  .css-13cymwt-control {
    align-items: center;
    border-radius: 2px;
    display: flex;
    min-height: 32px !important;
    height: 32px;

    .css-0 {
      padding: 0 0 0 10px;
      display: flex;
      align-items: center;
    }
  }
  .css-1jqq78o-placeholder {
    width: 0;
    margin: 0px;
    margin-left: -1px;
  }
  .css-t3ipsp-control {
    height: 32px;
    min-height: 32px !important;
    display: flex;
    align-items: center;
    .css-0 {
      padding: 0 0 0 10px;
      display: flex;
      align-items: center;
      .css-qbdosj-Input {
        width: 0;
        margin: 0px;
      }
    }
  }
  .css-1n6sfyn-MenuList {
    max-height: 220px;
  }
}
