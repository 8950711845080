@use '~src/scss/vars' as *;
@use '~src/scss/mixin' as *;

.ql-tooltip {
  z-index: 99;
}

.ql-toolbar {
  background: $color-primary-75;
}
