@use '~src/scss/vars' as *;
@use '~src/scss/mixin' as *;

.cmp-text-area {
  height: 72px;
  padding: 4px 8px;
  border: $default-border;
  background-color: $color-white;
  transition: $default-transition;
  border-radius: $input-border-radius;
  font-size: $body-size;
  min-height: $input-small;

  &:focus {
    // box-shadow: $focus-shadow;
    border: $default-border-primary;
  }

  &--error {
    border-color: $color-danger;
    &:focus {
      box-shadow: $error-shadow;
    }
  }

  &--disabled,
  &:disabled {
    background-color: #f5f5f5;
    // color: $color-black-100;
    // border: none;
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);

    @include hover;
  }

  &__resize--horizontal {
    resize: horizontal;
  }
  &__resize--vertical {
    resize: vertical;
  }
  &__resize--both {
    resize: both;
  }
  &__resize--none {
    resize: none;
  }
}
