@use '~src/scss/vars' as *;
@use '~src/scss/mixin' as *;

.cmp-input-autosize {
  &__icon {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);

    @include hover;
  }
}
